import { Container, Grid } from '@material-ui/core'
import React from 'react'
import Slider from 'react-slick';
import Layout from '../../components/layout'
import GridRow from '../../components/_grid-row'


import pageStyles from '../../assets/scss/class-internal.module.scss'
import nirvanaImg from '../../images/team/nirvana.jpg';
import nirvanaImg1 from '../../images/team/nirvana-1.png';
import nirvanaImg2 from '../../images/team/nirvana-2.png';
import nirvanaImg3 from '../../images/team/nirvana-3.png';

export default function Nirvana() {
    const sliderImages = [
        nirvanaImg,
        nirvanaImg1,
        nirvanaImg2,
        nirvanaImg3
    ];

    const settings = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 3.6,
        slidesToScroll: 3.6,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2.05,
                    slidesToScroll: 2.05,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1.02,
                    slidesToScroll: 1.02,
                    infinite: false,
                    dots: true
                }
            },
        ]
    };

    return (
        <React.Fragment>
            <Layout pageName="Meet the Team" backLink="/team">
                <div className={["class-internal", pageStyles.wrapper].join(" ")}>
                    <Container maxWidth="xl">
                        <GridRow>
                            {/* Slider section */}
                            <Grid container spacing={3} justify="center">
                                <Grid item xs={12}>
                                    {sliderImages?
                                    <Slider  {...settings}>
                                        {
                                            sliderImages.map(img => {
                                                return (
                                                    <div>
                                                        <img src={img}/>
                                                    </div>
                                                )
                                            })
                                        }

                                    </Slider>
                                    :
                                    ""

                                    }
                                </Grid>
                                {/* Content section */}
                                <Grid item xs={12} lg={8}>
                                    <div className={pageStyles.header}>
                                        <h2 className="h2">Nirvana Zaher</h2>
                                    </div>
                                    <div className={pageStyles.desc}>
                                        <p>
                                            As concept creator and managing founder of Beach Monkeyz, Nirvana believes in a holistic and integrative approach to fitness. She specializes in human performance and functional training strategies, incorporating principles from yoga, gymnastics, weight-lifting, sports conditioning, fundamental motor development techniques, and most importantly, PLAY in all of her programs.
                                            <br /> <br />
                                            For the past fifteen years, Nirvana has been educating and empowering people through workshops in fitness education, assisting anybody with a passion for health and fitness toward a career in this field. Her dedication to exercise science, and her cat-like curiosity for the limitless functions that the human body can perform have been primary pillars in her quest toward fitness and athletic excellence.
                                            <br /> <br />
                                            Nirvana’s motto? Divinity lies in the details, and passion is the foundation upon which any success is created.
                                            <br /> <br />
                                            To inquire or book, please send an email to info@beachmonkeyz.com.
                                        </p>
                                    </div>
                                    <Grid item xs={12} md={6} lg={5}>
                                        <div className={pageStyles.reserveButton}>
                                            <a href="mailto:info@beachmonkeyz.com" className={pageStyles.emailLink}>
                                                Send us an e-mail
                                            </a>
                                        </div>
                                    </Grid> 
                                </Grid>
                            </Grid>
                        </GridRow>
                    </Container>
                </div>
            </Layout>
        </React.Fragment>
    )
}
